









































































































import { Component, Watch, Vue } from "vue-property-decorator";

import { Kopf } from "@/models/Rechnungen/Kopf";
import { VerlagListeModel } from "@/models/VerlagListeModel";
import store from "@/store";
import VerlagsRechnungModule from "@/store/modules/vr";
import { catVue } from "@/util/logging";
import { getModule } from "vuex-module-decorators";
import Datum from "@/util/datum";

// eslint-disable-next-line

@Component({
  components: {
    VerlagSelector: () =>
      import(
        /* webpackChunkName: "verlag-selector" */ "@/components/verlage/VerlagSelector.vue"
      ),
  },
})
export default class PresseRechnungKopf extends Vue {
  //
  vrState = getModule(VerlagsRechnungModule, store);
  nummer = 0;
  datum = Datum.datumISO("");
  menu2 = false;
  modify = false;

  onUpdate(event: VerlagListeModel | null): void {
    console.log(event);
    if (event) {
      const k = Kopf.make(event);
      catVue.info(`${this.$options.name}:: Mutating ... ${this.value}`);
      this.vrState.updateRechnung(k);
      catVue.info(`${this.$options.name}:: Mutated ... ${this.value}`);
    }
    this.modify = false;
    return;
  }

  onSelect(event: VerlagListeModel | null): void {
    console.log(event);
    if (event) {
      const k = Kopf.make(event);
      catVue.info(`${this.$options.name}:: Mutating ... ${this.value}`);
      this.vrState.updateRechnung(k);
      catVue.info(`${this.$options.name}:: Mutated ... ${this.value}`);
    }
    return;
  }

  get value(): string | undefined {
    return this.vrState.aktuelleRechnung?.anschriftTitel;
  }

  @Watch("nummer")
  @Watch("datum")
  onKopfDataUpdate(): void {
    catVue.info(`Numner: ${this.nummer} — Datum: ${this.datum}`);
    if (this.vrState.aktuelleRechnung?.datum !== this.datum) {
      catVue.info(`--> UPDATED: Datum: ${this.datum}`);
      this.vrState.updateDatum(this.datum);
    }

    if (this.vrState.aktuelleRechnung?.nummer !== this.nummer) {
      catVue.info(`--> UPDATED: Nummer: ${this.nummer}`);
      this.vrState.updateNummer(this.nummer);
    }
  }

  created(): void {
    catVue.info(`${this.$options.name}:: CREATED ... ${this.value}`);
    catVue.info(
      `${this.$options.name}:: aktuell -> ${this.vrState.aktuelleRechnung}`
    );
  }
}
